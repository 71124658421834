<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="business_type"
                @change="handleBizTypeChange"
              >
                <a-select-option :key="2">暂落箱业务</a-select-option>
                <a-select-option :key="1">查验业务</a-select-option>
                <a-select-option :key="5">客服业务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="进场日期">
              <a-range-picker @change="onTempDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="箱号">
              <a-input v-model="queryParam.box_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 5">
            <a-form-item label="进库日期">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 1">
            <a-form-item label="录单时间">
              <a-range-picker @change="onCmsDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 5 || business_type === 2">
            <a-form-item label="操作员">
              <a-select
                showSearch :filter-option="filterOption"
                allowClear
                placeholder="请选择操作员"
                style="width: 100%"
                v-model="queryParam.operator_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="入库操作员">
              <a-select
                showSearch :filter-option="filterOption"
                allowClear
                placeholder="请选择入库操作员"
                style="width: 100%"
                v-model="queryParam.in_operator"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="出库操作员">
              <a-select
                showSearch :filter-option="filterOption"
                allowClear
                placeholder="请选择出库操作员"
                style="width: 100%"
                v-model="queryParam.out_operator"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 2">
            <a-form-item label="状态">
              <a-select
                allowClear
                placeholder="请选择状态"
                style="width: 100%"
                v-model="queryParam.status"
              >
                <a-select-option key="9">未出场</a-select-option>
                <a-select-option key="10">出场中</a-select-option>
                <a-select-option key="11">已出场</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col v-bind="GLOBAL.queryColSpan" v-if="business_type === 1">
            <a-form-item label="业务来源">
              <a-select
                showSearch
                allowClear
                placeholder="请选择业务来源"
                style="width: 100%"
                v-model="queryParam.source"
              >
                <a-select-option v-for="item in sourceOps" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" @click="handleExport">导出</a-button>
      <a-button type="primary" @click="exportAllWeighSheet">导出过磅清单</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      dynamicColumns
      :scroll="{ x: 'max-content' }"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :rowKey="function(record) { return getRandomCode(8) }"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="goodsItem" slot-scope="good" v-if="business_type === 5">
        <div v-for="item in good" :key="item.id">
          <span>{{ item.good_name }} 单位:{{ item.unit }} 数量:{{ item.num }} 库位:{{ item.stock_place }}</span>
        </div>
      </div>
      <div slot="boxInfoList" slot-scope="text" v-if="business_type === 2">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            <span>
              {{ text[0].box_name ? `类型：${text[0].box_name}` : '' }}
              {{ text[0].box_num ? ` 箱号：${text[0].box_num}` : '' }}
              {{ text[0].enter_time ? ` 进场时间：${moment(text[0].enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ text[0].exit_time ? ` 出场时间：${moment(text[0].exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
            <span style="color: #FAAD14;">{{ text.length !== 0 ? `(${text.length})单` : null }}</span>
          </template>
          <template slot="content">
            <span v-for="item in text" :key="item.id" style="display:block">
              {{ item.box_name ? `类型：${item.box_name}` : '' }}
              {{ item.box_num ? ` 箱号：${item.box_num}` : '' }}
              {{ item.enter_time ? ` 进场时间：${moment(item.enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ item.exit_time ? ` 出场时间：${moment(item.exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
          </template>
        </a-popover>
      </div>
      <div slot="weighInfoList" slot-scope="text" v-if="business_type === 2">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            <span>
              {{ text[0].plate_no ? `车牌：${text[0].plate_no}` : '' }}
              {{ text[0].weigh_type ? ` 类型：${text[0].weigh_type === 'weight' ? '重' : '空'}` : '' }}
              {{ text[0].weigh_date ? ` 过磅时间：${text[0].weigh_date}` : '' }}
              {{ text[0].exit_date ? ` 出场时间：${text[0].exit_date}` : '' }}
              {{ text[0].weight ? ` 过磅重量：${text[0].weight}` : '' }}
            </span>
            <span style="color: #FAAD14;">{{ text.length !== 0 ? `(${text.length})单` : null }}</span>
          </template>
          <template slot="content">
            <span v-for="item in text" :key="item.id" style="display:block">
              {{ text[0].plate_no ? `车牌：${text[0].plate_no}` : '' }}
              {{ text[0].weigh_type ? ` 类型：${text[0].weigh_type === 'weight' ? '重' : '空'}` : '' }}
              {{ text[0].weigh_date ? ` 过磅时间：${text[0].weigh_date}` : '' }}
              {{ text[0].exit_date ? ` 出场时间：${text[0].exit_date}` : '' }}
              {{ text[0].weight ? ` 过磅重量：${text[0].weight}` : '' }}
            </span>
          </template>
        </a-popover>
      </div>
      <!-- <div slot="feeItem" slot-scope="fee">
        <div v-for="item in fee" :key="item.id" :class="feeStatusColor(item.fee_status)">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span v-if="item.charge_type === 1">{{ item.fee_name }}:现金:{{ item.amount }}</span>
          <span v-else-if="item.charge_type === 2">{{ item.fee_name }}:月结:{{ item.amount }}</span>
          <span v-else-if="item.charge_type === 3">{{ item.fee_name }}:现金,月结:{{ item.amount }}</span>
        </div>
      </div> -->
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getCustomer, downloadExcel } from '@/api/common'
import moment from 'moment'
import { getSystemInfoOption } from '@/api/system'
import { wmsInStat, exportWmsStat, getWmsDataOption, exportWeighSheet } from '@/api/wms'
import { getRandomCode, filterOption } from '@/utils/util'
import { getCmsMetaOption } from '@/api/cms'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      userOps: [],
      fees: [],
      business_type: 2,
      showTotal: '',
      sourceOps: [],
      columns: [],
      tmpColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '操作员',
          dataIndex: 'operator_id',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return text
          }
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '过磅信息',
          dataIndex: 'weigh_info_list',
          scopedSlots: { customRender: 'weighInfoList' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        }
        // {
        //   title: '费用',
        //   dataIndex: 'fees',
        //   scopedSlots: { customRender: 'feeItem' }
        // }
      ],
      cmsColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name',
          width: 120
        },
        {
          title: '品名',
          dataIndex: 'total_name',
          width: 100
        },
        {
          title: '件数',
          dataIndex: 'num',
          width: 80
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight',
          width: 80
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight',
          width: 80
        },
        {
          title: '总天数',
          dataIndex: 'wms_days',
          width: 80
        },
        {
          title: '总体积',
          dataIndex: 'total_volume',
          width: 80
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name',
          width: 100
        },
        {
          title: '布控指令',
          dataIndex: 'control_command',
          width: 100
        },
        {
          title: '箱型',
          dataIndex: 'box_type_name',
          width: 80
        },
        {
          title: '箱量',
          dataIndex: 'box_num',
          width: 80
        },
        {
          title: '是否押运',
          dataIndex: 'escort',
          width: 100,
          customRender: (text) => {
            return text ? '是' : '否'
          }
        },
        {
          title: '工号',
          dataIndex: 'worker_num',
          width: 80
        },
        {
          title: '金额',
          dataIndex: 'charge',
          width: 80
        },
        {
          title: '查验情况简述',
          dataIndex: 'sketch',
          width: 150
        },
        {
          title: '货物处置',
          dataIndex: 'commodity_deal',
          width: 100
        },
        {
          title: '查验方式',
          dataIndex: 'inspect',
          width: 100
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100
        },
        {
          title: '仓储进库日期',
          dataIndex: 'in_date',
          width: 130,
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '仓储出库日期',
          dataIndex: 'out_date',
          width: 130,
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '入库总立方',
          dataIndex: 'in_cube',
          width: 130
        },
        {
          title: '入库操作员',
          dataIndex: 'in_operator',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return text
          },
          width: 130
        },
        {
          title: '出库总立方',
          dataIndex: 'out_cube',
          width: 130
        },
        {
          title: '出库操作员',
          dataIndex: 'out_operator',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return text
          },
          width: 130
        }
      ],
      cumsColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '入库作业编号',
          dataIndex: 'operate_num'
        },
        {
          title: '货物信息',
          dataIndex: 'goods',
          scopedSlots: { customRender: 'goodsItem' }
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 120
        },
        {
          title: '箱型箱量',
          dataIndex: 'box_items',
          customRender: (text) => {
            const boxs = []
            text && text.forEach(b => {
              boxs.push(b.box_name + ':' + b.box_count)
            })
            return boxs.join('、')
          },
          width: 120
        },
        {
          title: '操作员',
          dataIndex: 'operator_id',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '入库总立方',
          dataIndex: 'in_cube'
        },
        {
          title: '进库备注',
          dataIndex: 'remark',
          width: 200
        },
        {
          title: '反馈备注',
          dataIndex: 'feedback_remark',
          width: 200
        },
        {
          title: '进库日期',
          dataIndex: 'record_time',
          customRender: text => {
            return text ? text.substring(0, 10) : ''
          }
        }
      ],
      loadData: parameter => {
        return wmsInStat(this.getBusinessType(this.business_type), Object.assign(parameter, this.queryParam))
          .then(res => {
            if (this.business_type === 1) {
              if (typeof res.total_row !== 'undefined') {
                this.showTotal = '箱型合计：' + res.total_row.box + ' 入库总立方：' + res.total_row.in + ' 出库总立方：' + res.total_row.out
              } else {
                this.showTotal = '箱型合计：无'
              }
            } else if (this.business_type === 5) {
              this.showTotal = '箱型合计：' + res.total_row.box + ' 入库总立方：' + res.total_row.in
            } else {
              this.showTotal = '箱型合计：' + res.total_row
            }
            return res
          })
      },
      operatorOps: []
    }
  },
  created() {
    getSystemInfoOption('user').then(v => {
      this.userOps = v
    })
    getWmsDataOption('operator').then(v => {
        this.operatorOps = v
    })
    getCmsMetaOption('source').then(v => {
      this.sourceOps = v
    })
    this.columns = this.tmpColumns
  },
  methods: {
    getRandomCode,
    filterOption,
    moment,
    handleBizTypeChange(a) {
      switch (a) {
        case 1:
          this.columns = this.cmsColumns
          break;
        case 2:
          this.columns = this.tmpColumns
          break;
        case 5:
          this.columns = this.cumsColumns
          break;
        default:
          this.business_type = 2
          this.columns = this.tmpColumns
          break;
      }
      this.$refs.table.refresh(true)
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['record_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['record_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['record_start_time'] = null
        this.queryParam['record_end_time'] = null
      }
    },
    onTempDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['enter_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_time'] = null
        this.queryParam['enter_end_time'] = null
      }
    },
    onCmsDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['create_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['create_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['create_start_time'] = null
        this.queryParam['create_end_time'] = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        this.customerInfo = undefined
        this.queryParam['customer_id'] = null
      } else {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
      }
      this.customerOps = []
      this.fetching = false
    },
    handleExport() {
      this.queryParam['business_type'] = this.business_type
      exportWmsStat('in', {
        ...this.queryParam
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    exportAllWeighSheet () {
      if (!this.queryParam.enter_start_time || !this.queryParam.enter_end_time) {
        this.$message.error('请选择进场日期')
        return
      }
      exportWeighSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    getBusinessType(value) {
      switch (value) {
        case 1:
          return 'stat_cms'
        case 2:
          return 'stat_tmp'
        case 5:
          return 'stat_cums'
        default:
          this.business_type = 2
          this.columns = this.tmpColumns
          return 'stat_tmp'
      }
    }
  }
}
</script>
